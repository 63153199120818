<template>
  <div class="series">
    <div class="banner">
      <div class="textBox">
        <div class="title">Agility 9533 Series</div>
        <div class="smallTitle">2D Wireless Barcode Scanner</div>
        <div class="iconTextBox">
          <div class="left">
            <img src="../../../assets/9520网站配图/图标/路径 5.png" alt="" />
            Barcode
          </div>
          <div class="right">
            <img src="../../../assets/9520网站配图/图标/路径 4.png" alt="" />
            QR/PDF41/DPM
          </div>
        </div>
      </div>
    </div>
    <ImgVideo :video="video"> </ImgVideo>
    <div class="high-definition">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 1.png" alt="" />
          High-definition camera
        </div>
        <div class="text">
          Equipped with advanced decoding algorithm and dual-color lighting, it
          rapidly identifies in low-light conditions.
        </div>
      </div>
    </div>
    <div class="preciseRecognition">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/分组 4.png" alt="" />
          Precise recognition
        </div>
        <div class="text">
          It can rapidly and precisely identify damaged, stained, wrinkled, and
          colored barcodes, suitable for metal and DPM codes in light
          manufacturing.
        </div>
      </div>
    </div>
    <div class="chargingDock">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/7.png" alt="" />
          Charging dock
        </div>
        <div class="text">
          Operate continuously for more than 12 hours with a data transmission
          distance of up to 100 meters in open spaces.
        </div>
      </div>
    </div>
    <div class="meter">
      <div class="box">
        <img src="../../../assets/9520网站配图/图标/1.5 Meter.png" alt="" />
        <div class="title"></div>
        <div class="title">
          <div>Capable of enduring multiple</div>
          <div>1.5m cement falls</div>
        </div>
        <div class="text">
          It features a dual-color ABS+TPU integrated design, offering excellent
          shock and drop resistance. It remains functional even after falling
          from a desktop, ensuring uncompromised performance.
        </div>
        <div class="smallText">*Through the Compax laboratory trigger test</div>
      </div>
    </div>
    <div class="product">
      <div class="line"></div>
      <div class="Search">
        <div class="text">Search</div>
      </div>
      <div class="Pairing">
        <div class="text">Pairing</div>
      </div>
      <div class="Dock">
        <div class="text">Wireless Receiving and Charging Dock</div>
      </div>
      <div class="scanWindow">
        <div class="text">Scan Window</div>
      </div>
      <div class="Button">
        <div class="text">Button</div>
      </div>
      <div class="Buzzer">
        <div class="text">Buzzer</div>
      </div>
      <div class="Charging">
        <div class="text">Charging Port (Type-C)</div>
      </div>
    </div>
    <div class="Excellent">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 20.png" alt="" />
          <div>
            <div>Excellent field of view</div>
            <div>& decoding depth.</div>
          </div>
        </div>
        <div class="text">
          The reading depth of a standard 13mil EAN13 code is 6-46cm, with a
          horizontal field of view angle of 50° and a vertical field of view
          angle of 38°.
        </div>
      </div>
    </div>
    <div class="IP52Protection">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/防水.png" alt="" />
          IP52 Protection
        </div>
        <div class="text">
          It can operate normally within a humidity range of 5 - 95%
          (non-condensing), and the IP52 protection rating ensures that the
          device can continue to operate normally after being splashed with
          liquid.
        </div>
        <div class="smallText">*Through the Compax laboratory trigger test</div>
      </div>
    </div>
    <div class="ESDProtection">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/分组 3.png" alt="" />
          ESD Protection
        </div>
        <div class="text">
          It can operate normally in an environment with contact discharge of 8
          KV and air discharge of 15 KV, suitable for diverse scanning needs in
          harsh working environments.
        </div>
      </div>
    </div>
    <div class="OS">
      <div class="title">1.2GHz Dual-Core SoC, powered by Linux OS.</div>
      <div class="text">
        Equipped with advanced decoding software and hardware, it rapidly and
        accurately identifies damaged, stained, wrinkled, and colored barcodes,
        suitable for metal and DPM code recognition in light manufacturing.
      </div>
    </div>
    <div class="Agility9533Series">
      <div class="w">
        <div class="titleBox">
          <div class="title">Agility 9533 Series</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">CPU</div>
              <div class="text">1.2G Dual core</div>
            </div>
            <div class="itemA">
              <div class="title">Light Source</div>
              <div class="text">LED White light</div>
            </div>
            <div class="itemA">
              <div class="title">Aiming Light</div>
              <div class="text">Green Dot</div>
            </div>
            <div class="itemA">
              <div class="title">Image Sensor</div>
              <div class="text">1280*800pixels (Global Shutter)</div>
            </div>
            <div class="itemA">
              <div class="title">Imaging Speed</div>
              <div class="text">60FPS/S</div>
            </div>
            <div class="itemA">
              <div class="title">Min. Print Contrast</div>
              <div class="text">20% Reflection Difference</div>
            </div>
            <div class="itemA">
              <div class="title">Motion Tolerance</div>
              <div class="text">2M/S</div>
            </div>
            <div class="itemA">
              <div class="title">Image Field of View</div>
              <div class="text">Horizontal: 50° Vertical: 38°</div>
            </div>
            <div class="itemA">
              <div class="title">Reading Indicators</div>
              <div class="text">LED light&Buzzer</div>
              <div class="text">(adjustable tone and volume)</div>
            </div>
            <div class="itemA">
              <div class="title">Input Voltage</div>
              <div class="text">5 VDC (+/- 10%)/500mA</div>
            </div>
            <div class="itemB">
              <div class="title">Current</div>
              <div class="text">Working current (Standard):</div>
              <div class="text">&lt; 280 mA</div>
              <div class="text">Standby current (Standard):</div>
              <div class="text">&lt; 130 mA</div>
            </div>
            <div class="itemB">
              <div class="title">Reading Angle</div>
              <div class="text">Pitch: 0-360° ;</div>
              <div class="text">Roll(Tilt): ± 60° ;</div>
              <div class="text">Skew (Yaw): ± 60°</div>
            </div>
            <div class="itemC">
              <div class="title">Decoding Capability</div>
              <div class="text">
                1D Barcode/Linear Code: Auto-distinguishes & decodes all
                standard 1Ds, incl. GS1 DataBar™.
              </div>
              <div class="text">
                2D Barcode: Aztec, Data Matrix, Maxi, QR, HAN XIN, Micro QR.
              </div>
              <div class="text">
                Stacked Barcode: GS1 DataBar Expanded, Stacked, Omnidirectional,
                Composite, MicroPDF417, PDF417.
              </div>
            </div>
            <div class="itemD">
              <div class="title">Typical depth of Field</div>
              <div class="table">
                <div class="item">
                  <div class="text">Precision</div>
                  <div class="text">3.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">3.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">13.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">10mil</div>
                  <div class="text">10mil</div>
                  <div class="text">20mil</div>
                </div>
                <div class="item">
                  <div class="text">Barcode</div>
                  <div class="text">CODE 128</div>
                  <div class="text">CODE128</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">PDF417</div>
                  <div class="text">QR</div>
                  <div class="text">DM</div>
                  <div class="text">QR</div>
                </div>
                <div class="item">
                  <div class="text">Depth of Field</div>
                  <div class="text">120mm—160mm</div>
                  <div class="text">100mm—210mm</div>
                  <div class="text">120mm—160mm</div>
                  <div class="text">100mm—220mm</div>
                  <div class="text">60mm—470mm</div>
                  <div class="text">100mm—210mm</div>
                  <div class="text">90mm—280mm</div>
                  <div class="text">90mm—240mm</div>
                  <div class="text">60mm—440mm</div>
                </div>
              </div>
            </div>
            <div class="itemE">
              <div class="title">Environmental Parameter</div>
              <div class="text">
                ESD Static Protection: Contact discharge 8 kV; Air discharge 15
                kV
              </div>
              <div class="text">
                Drop Resistance: Withstands multiple drops from 1.5 meters onto
                a cement surface
              </div>
              <div class="text">
                Temperature: Operating -10 to 50 °C; Storage/Transport: -40 to
                70 °C
              </div>
              <div class="text">Humidity (non-condensing): 5 - 95%</div>
              <div class="text">Ambient Light: 0 to 100,000 lux</div>
            </div>
            <div class="itemD">
              <div class="title">Cordless Parameter</div>
              <div class="text">Transmission Mode:</div>
              <div class="text">
                Wireless 2.4G, optional bluetooth interface
              </div>
              <div class="text">Transmission Distance:</div>
              <div class="text">2.4G is 100M distance in open space</div>
              <div class="text">Battery capacity:</div>
              <div class="text">2000mAh</div>
              <div class="text">Working Time:</div>
              <div class="text">＞12H</div>
              <div class="text">Charging time:</div>
              <div class="text">4-5H</div>
            </div>
            <div class="itemF">
              <div class="title">Dimensions</div>
              <div class="rightImg"></div>
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">9533XD Brochure</div>
              <div class="text">PDF(1.47MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Agility_9533XD.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">9533XD User Manual</div>
              <div class="text">PDF(422KB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Agility_9533XD_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgVideo from '@/components/imgVideo'
import video from '../../../assets/9530网站配图/Agility_9533_Series.mp4'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video
    }
  }
}
</script>

<style lang="less" scoped>
.series {
  .banner {
    position: relative;
    height: 1000px;
    background: url('../../../assets/9530网站配图/头图.png') no-repeat center;
    background-size: cover;
    .textBox {
      position: absolute;
      top: 101px;
      left: 425px;
      .title {
        color: rgba(0, 92, 255, 1);
        font-size: 72px;
        font-weight: 600;
        line-height: 95.47px;
      }
      .smallTitle {
        margin: 16px 0px 60px 0px;
        color: #fff;
        font-size: 36px;
        line-height: 52.13px;
      }
      .iconTextBox {
        img {
          margin-right: 10px;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 47px;
            height: 33.04px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: 36.37px;
            height: 33.17px;
          }
        }
        display: flex;
        justify-content: space-between;
        width: 430px;
        display: flex;
        color: #fff;
        font-size: 24px;
        line-height: 34.75px;
      }
    }
  }
  .high-definition {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/灯介绍.png') no-repeat center;
    background-size: cover;
    .box {
      color: #fff;
      position: absolute;
      top: 417px;
      left: 306px;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 23px;
          width: 75px;
          height: 85px;
        }
      }
      .text {
        width: 601px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
    }
  }
  .preciseRecognition {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/扫码场景.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      position: absolute;
      top: 182px;
      right: 307px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 17px;
          width: 75px;
          height: 75px;
        }
      }
      .text {
        width: 736px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
    }
  }
  .chargingDock {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/充电底座动画.gif') no-repeat
      center;
    background-size: cover;
    .box {
      color: #fff;
      position: absolute;
      top: 417px;
      left: 306px;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 25px;
          width: 56px;
          height: 90px;
        }
      }
      .text {
        width: 601px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
    }
  }
  .meter {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/防摔.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      color: #fff;
      position: absolute;
      top: 357px;
      left: 1007px;
      img {
        width: 335px;
        height: 120px;
      }
      .title {
        margin: 19px 0px 32px 0px;
        font-size: 36px;
        line-height: 50px;
        font-weight: 600;
      }
      .text {
        width: 601px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
      }
      .smallText {
        font-size: 14px;
        margin-top: 72px;
      }
    }
  }
  .OS {
    height: 1080px;
    background: url('../../../assets/9530网站配图/cpu2.png.png') no-repeat
      center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    .title {
      margin-top: 134px;
      line-height: 50px;
      font-weight: 600;
      font-size: 36px;
    }
    .text {
      margin-top: 17px;
      width: 988px;
      font-size: 28px;
      line-height: 40.54px;
      text-align: justify;
    }
  }
  .ESDProtection {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/防静电.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      position: absolute;
      top: 449px;
      right: 291px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 17px;
          width: 52px;
          height: 60px;
        }
      }
      .text {
        width: 620px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
    }
  }
  .IP52Protection {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/防水.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      position: absolute;
      top: 441px;
      left: 303px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 17px;
          width: 57px;
          height: 56px;
        }
      }
      .text {
        width: 601px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
      .smallText {
        font-size: 14px;
        margin-top: 72px;
      }
    }
  }
  .Excellent {
    height: 1080px;
    position: relative;
    background: url('../../../assets/9530网站配图/扫描角度.png') no-repeat
      center;
    background-size: cover;
    .box {
      color: #fff;
      position: absolute;
      top: 107px;
      left: 1009px;
      .title {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        img {
          margin-right: 25px;
          width: 83px;
          height: 72px;
        }
      }
      .text {
        width: 596px;
        font-size: 28px;
        line-height: 40.54px;
        text-align: justify;
        margin-top: 27px;
      }
    }
  }
  .product {
    position: relative;
    height: 1080px;
    background: url('../../../assets/9530网站配图/介绍页面.png') no-repeat
      center;
    background-size: cover;
    .line {
      top: 170px;
      left: 324.5px;
      position: absolute;
      height: 578px;
      width: 1284.5px;
      background: url('../../../assets/9520网站配图/图标/9530连接线.png')
        no-repeat center;
      background-size: cover;
    }
    .text {
      color: #fff;
      font-size: 28px;
      line-height: 40.54px;
    }
    .Search {
      position: absolute;
      top: 429px;
      right: 1549px;
    }
    .Pairing {
      position: absolute;
      top: 548px;
      right: 1554px;
    }
    .scanWindow {
      position: absolute;
      top: 381px;
      right: 841px;
    }
    .Button {
      position: absolute;
      top: 577px;
      right: 672px;
    }
    .Buzzer {
      position: absolute;
      top: 150px;
      right: 377px;
    }
    .Charging {
      position: absolute;
      bottom: 324px;
      right: 149px;
    }
    .Dock {
      position: absolute;
      top: 743px;
      left: 743px;
      width: 337px;
      text-align: center;
    }
  }
  .Agility9533Series {
    background: rgba(0, 0, 0, 1);
    padding: 103px 11px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 30px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 259px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 100px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 700px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
        .table {
          display: flex;
          .item {
            margin-right: 22px;
          }
        }
      }
      .itemE {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 698px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemF {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 698px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 16px;
          right: 195px;
          width: 299px;
          height: 244px;
          background: url('../../../assets/9530网站配图/尺寸图.png') no-repeat
            center;
          background-size: cover;
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
